import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import WeaponStatsModel from "@/data-models/cs2-individual-weapon-stats.mjs";
import CsgoStatsWeapons from "@/data-models/csgo-stats-weapons.mjs";
import { WEAPONS } from "@/game-cs2-marketing/constants.mjs";

const constantOptions = {
  networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
};

function fetchWeaponsStats() {
  const url = `${appURLs.UTILS_STATIC}/csgo/stats-test/data/weapons_stats:queue=competitive_long&map_code=ALL&rank=ALL`;
  return getData(
    url,
    CsgoStatsWeapons,
    ["cs2", "stats", "weapons"],
    constantOptions,
  );
}

function fetchWeaponStats([key]) {
  const [weaponId] =
    Object.entries(WEAPONS).find(
      ([, weapon]) =>
        weapon.key === key &&
        weapon.category !== "gear" &&
        weapon.category !== "grenades",
    ) || [];
  if (!weaponId) return;
  const url = `${appURLs.UTILS_STATIC}/csgo/stats-test/data/indiv_weapons_stats:queue=competitive_long&map_code=ALL&rank=ALL&weapon_id=${weaponId}`;
  return Promise.all([
    getData(
      url,
      WeaponStatsModel,
      ["cs2", "stats", "pastWeaponStats", weaponId],
      constantOptions,
    ),
    fetchWeaponsStats(),
  ]);
}

export default fetchWeaponStats;
