import createModel, { Silent } from "@/__main__/data-model.mjs";

const baseModel = {
  num_matches: Silent(Number),
  num_rounds: Silent(Number),
  num_kills: Silent(Number),
  "head_%": Silent(Number),
  "body_%": Silent(Number),
  "leg_%": Silent(Number),
  "stomach_%": Silent(Number),
  kills_per_round: Silent(Number),
  avg_hit_range: Silent(Number),
  headshot_kills_per_round: Silent(Number),
  headshot_kills_ratio: Silent(Number),
};

const model = {
  ...baseModel,
  past_days_stats: [{ dt: Date, ...baseModel }],
};

const WeaponStatsModel = createModel(model);

export default WeaponStatsModel;
